/* eslint-disable react/no-unused-state */
import React, { useState } from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import PropTypes from "prop-types";
import EditTable from "../../shared/components/table/DataPaginationTable";
import { useQuery } from "react-apollo";
import Loading from "../../shared/components/Loading";
import Pagination from "../../shared/components/pagination/Pagination";
import { ALL_ADMINS } from "./Admins-gql";
import * as moment from "moment";
import AdminEditModal from "./Components/AdminEditModal";
import AdminDeleteModal from "./Components/AdminDeleteModal";
import { connect } from "react-redux";
import { addDetails } from "../../redux/actions/authActions";
import useBanner from "./Hook/useBanner";
import { ALLPRODUCTS } from "../ApproveDress/ApproveDress-gql";

const StatusFormatter = ({ value, row }, toggle, deleteToggle) => (
  <>
    <span
      style={{
        cursor: "pointer",
        marginRight: 10,
      }}
      onClick={() => toggle(row, "Edit")}
      className="badge badge-success"
    >
      Edit
    </span>
    <span
      style={{
        cursor: "pointer",
      }}
      onClick={() => deleteToggle(row)}
      className="badge badge-success"
    >
      Delete
    </span>
  </>
);

const customerFormmater = ({ value }) => (
  <div>
    {value.firstName} {value.lastName}
  </div>
);

StatusFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const dateFormatter = ({ value }) => (
  <div>{moment(value).format("YYYY-MM-DD")}</div>
);

export default connect((state) => ({ themeName: state.theme.className }), {
  setDetails: addDetails,
})((props) => {
  // const { data, error, loading } = useQuery(ALL_ADMINS);
  // const { data, loading, error, refetch } = useQuery(ALLPRODUCTS);
  const [data, loading, error] = useBanner();

  console.log("data", data);

  return error ? (
    <div>Error</div>
  ) : loading ? (
    <Loading />
  ) : (
    <AdminContainer data={data} {...props} />
  );
});

const AdminContainer = ({ data, themeName, setDetails }) => {
  const [detailInfo, setDetailInfo] = useState();
  const heads = [
    {
      key: "title",
      name: "Title",
      sortable: true,
      // formatter: customerFormmater,
    },
    {
      key: "description",
      name: "Description",
      sortable: true,
    },
    // {
    //   key: "createdAt",
    //   name: "Create Date",
    //   sortable: true,
    //   formatter: dateFormatter,
    // },
    {
      formatter: (value) => StatusFormatter(value, toggle, deleteToggle),
    },
  ];

  const filterRows = (originalRows, pageNumber, rowsOnPage) => {
    const rowsFrom = rowsOnPage * (pageNumber - 1);
    const rowsTo = rowsFrom + rowsOnPage;
    return originalRows.slice(rowsFrom, rowsTo);
  };

  const onSorting = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    };
    const { rows, pageOfItems, itemsToShow } = state;
    if (sortDirection !== "NONE") {
      let sortRows = [...rows].sort(comparer);
      sortRows = filterRows(sortRows, pageOfItems, itemsToShow);
      setState((prevState) => ({ ...prevState, rowsToShow: sortRows }));
      return sortRows;
    }
    const sortRows = filterRows(rows, pageOfItems, itemsToShow);
    setState((prevState) => ({ ...prevState, rowsToShow: sortRows }));
    return sortRows;
  };

  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [type, setType] = useState("");

  const toggle = (dress, theType) => {
    setType(theType);
    setDetailInfo(!modal ? dress : {});
    setModal(!modal);
  };

  const deleteToggle = (dress) => {
    setDetailInfo(!deleteModal ? dress : {});
    setDeleteModal(!deleteModal);
  };

  const initialPageNumber = 1;
  const initialRowsCount = 10;

  const originalRows = data;
  const currentPageRows = filterRows(data, initialPageNumber, initialRowsCount);

  const [state, setState] = useState({
    rows: data,
    rowsToShow: currentPageRows,
    pageOfItems: initialPageNumber,
    itemsToShow: initialRowsCount,
  });

  const onChangePage = (pageOfItems) => {
    const { rows, itemsToShow } = state;
    if (pageOfItems) {
      const rowsToShow = filterRows(rows, pageOfItems, itemsToShow);
      setState((prevState) => ({ ...prevState, rowsToShow, pageOfItems }));
    }
  };

  return (
    <div className={themeName}>
      {modal && (
        <AdminEditModal
          modal={modal}
          toggle={toggle}
          color="primary"
          title={`${type} Admin!`}
          header
          btn="Default"
          typed={type}
          details={detailInfo}
        />
      )}
      {deleteModal && (
        <AdminDeleteModal
          modal={deleteModal}
          toggle={deleteToggle}
          color="danger"
          title="Delete Banner!"
          header
          btn="Default"
          details={detailInfo}
        />
      )}
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Banner list</h5>
            </div>
            <div>
              <Button
                className="modal_ok"
                color="primary"
                onClick={() => toggle({}, "New")}
              >
                Add New Banner
              </Button>
            </div>
            {console.log("row", state)}

            <EditTable heads={heads} rows={data} onSorting={onSorting} />
            <Pagination
              itemsCount={data.length}
              itemsToShow={state.itemsToShow}
              pageOfItems={state.pageOfItems}
              onChangePage={onChangePage}
            />
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};
