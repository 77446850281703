import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Col, Modal } from "reactstrap";
import classNames from "classnames";
import { useMutation } from "react-apollo";
import { PAY_BACK_CASH } from "../Cashback-gql";

const ModalComponent = ({
  color,
  colored,
  header,
  rtl,
  toggle,
  modal,
  details,
  themeName,
  admin,
}) => {
  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }

  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  const [paybackCash] = useMutation(PAY_BACK_CASH);

  const paybackCashFunc = async () => {
    await paybackCash({
      variables: {
        id: details.id,
        admin: admin.id,
      },
    })
      .then(({ data }) => {
        if (data) {
          toggle();
        }
      })
      .catch((error) => error && console.log(error));
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName={`${rtl.direction}-support`}
      className={`modal-dialog--${color} ${modalClass} ${themeName}`}
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
        {header ? "" : Icon}
        <h4
          className="text-modal  modal__title"
          style={{
            color: "#fff",
          }}
        >
          Pay user cash back
        </h4>
      </div>
      <div className="modal__body">
        <Col>
          <div>
            name: {details.details && details.details.firstName}{" "}
            {details.details && details.details.middleName}{" "}
            {details.details && details.details.lastName}
          </div>
          <div>email: {details.details && details.details.email} </div>
          <div>mobile: {details.details && details.details.mobile} </div>
          <div>
            Transfet to: {details.details && details.details.transfetTo}{" "}
          </div>
          <div>IBan: {details.details && details.details.iBan} </div>
          <div>Cash: {details.details && details.user.storyCredit} </div>
        </Col>
      </div>
      <ButtonToolbar className="modal__footer">
        <Button
          className="modal_cancel"
          outline={colored}
          color={"danger"}
          onClick={toggle}
        >
          Cancel
        </Button>{" "}
        <button
          onClick={paybackCashFunc}
          type="submit"
          className="modal_ok btn btn-primary"
        >
          Pay back
        </button>
      </ButtonToolbar>
    </Modal>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
};

export default connect((state) => ({
  themeName: state.theme.className,
  rtl: state.rtl,
  details: state.user.details,
  admin: state.user.user,
}))(ModalComponent);
