import React, { Component, Fragment } from "react";
import { connect, Provider } from "react-redux";
import {  BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
// import * as firebase from 'firebase/app';
import "firebase/auth";
// eslint-disable-next-line import/no-extraneous-dependencies
import "bootstrap/dist/css/bootstrap.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "../../scss/app.scss";
import PropTypes from "prop-types";
import Router from "./Router";
import { store, persistor } from "./store";
import ScrollToTop from "./ScrollToTop";
import { config as i18nextConfig } from "../../translations";
// import Auth0Provider from '../../shared/components/auth/withAuth0';
// import auth0Config from '../../config/auth0';
import { PersistGate } from "redux-persist/integration/react";

import { ApolloProvider } from "react-apollo";
import client from "../../config/Client";

import "react-phone-input-2/lib/style.css";

i18next.init(i18nextConfig);

const ThemeComponent = ({ children, themeName }) => {
  const theme = createMuiTheme({
    palette: {
      type: themeName === "theme-dark" ? "dark" : "light",
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

ThemeComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  themeName: PropTypes.string.isRequired,
};

const ConnectedThemeComponent = connect((state) => ({
  themeName: state.theme.className,
}))(ThemeComponent);

class App extends Component {
  componentDidMount() {
    window.addEventListener("load", () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
    // firebase.initializeApp(firebaseConfig);
  }

  onRedirectCallbackAuth0 = (appState) => {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  render() {
    return (
      <ApolloProvider client={client}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {/* <Auth0Provider
          domain={auth0Config.domain}
          client_id={auth0Config.clientId}
          redirect_uri={`${window.location.origin}/admin/dashboard`}
          returnTo={`${window.location.origin}/admin/dashboard`}
          onRedirectCallback={this.onRedirectCallbackAuth0}
        > */}
            <BrowserRouter>
              <I18nextProvider i18n={i18next}>
                <ScrollToTop>
                  <Fragment>
                    <ConnectedThemeComponent>
                      <Router />
                    </ConnectedThemeComponent>
                  </Fragment>
                </ScrollToTop>
              </I18nextProvider>
            </BrowserRouter>
            {/* </Auth0Provider> */}
          </PersistGate>
        </Provider>
      </ApolloProvider>
    );
  }
}

export default App;
