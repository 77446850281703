import {
  AUTHENTICATE,
  AUTHENTICATE_ERROR_AUTH,
  LOGIN,
  LOGOUT,
  ADD_DETAILS,
  CHANGE_LANGUAGE,
} from "../actions/authActions";

const initialState = {
  user: {},
  language: "en",
  currency: "",
  fullName: "Roman Johanson",
  avatar: "",
  loggedIn: false,
  details: {},
};

export default (state = initialState, { type, payload, user, error }) => {
  switch (type) {
    case AUTHENTICATE:
      return { fullName: user.name, avatar: user.avatar };
    case AUTHENTICATE_ERROR_AUTH:
      return { error: error };
    case LOGIN:
      return {
        ...state,
        user: payload.user,
        loggedIn: true,
      };
    case LOGOUT:
      return {
        ...state,
        user: {},
        loggedIn: false,
      };
    case ADD_DETAILS:
      return {
        ...state,
        details: payload,
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: payload,
      };
    default:
      return state;
  }
};
