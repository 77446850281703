import React from "react";
import { Col, Container, Row } from "reactstrap";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { RTLProps } from "../../shared/prop-types/ReducerProps";
import SessionShort from "./MobileApp/components/SessionShort";
import ActiveUsersShort from "./MobileApp/components/ActiveUsersShort";
import NewUsersShort from "./MobileApp/components/NewUsersShort";
import PageViewsShort from "./MobileApp/components/PageViewsShort";
import { useQuery } from "react-apollo";
import { ADMIN_DASHBOARD } from "./Dashboard-gql";
import Loading from "../../shared/components/Loading";

const DefaultDashboard = ({ t, rtl }) => {
  const { data, loading, error } = useQuery(ADMIN_DASHBOARD);

  return error ? (
    <div>Error</div>
  ) : loading ? (
    <Loading />
  ) : (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t("dashboard.page_title")}</h3>
        </Col>
      </Row>
      <Row>
        <SessionShort data={data.adminDashboard.users} />
        <ActiveUsersShort data={data.adminDashboard.admins} />
        <NewUsersShort data={data.adminDashboard.products} />
        <PageViewsShort />
      </Row>
    </Container>
  );
};
DefaultDashboard.propTypes = {
  t: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired,
};

export default compose(
  withTranslation("common"),
  connect((state) => ({
    rtl: state.rtl,
  }))
)(DefaultDashboard);
