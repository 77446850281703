import gql from "graphql-tag";
import {
  ADMIN_DETAILS,
  LOGIN_ADMIN_DETAILS,
} from "../../../containers/Admins/Admins-gql";

export const ADMIN_LOGIN = gql`
  mutation loginAdmin($username: String, $password: String) {
    loginAdmin(username: $username, password: $password) {
      ${LOGIN_ADMIN_DETAILS}
    }
  }
`;
