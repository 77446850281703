import gql from "graphql-tag";

export const ALL_ORDERS = gql`
  query orders {
    orders {
      id
      createdAt
      user {
        account {
          id
          firstName
          lastName
          country
          email
          mobile
        }
      }
      total
      ref
      status
      notes
      items {
        id
        product {
          id
        }
        user {
          account {
            id
            firstName
            lastName
            country
            email
            mobile
          }
        }
        qty
        total
      }
      address {
        id
        city
        appartment
        street
      }
    }
  }
`;

export const CHANGE_ORDER_STATUS = gql`
  mutation changeStatus($id: String, $status: String) {
    changeStatus(id: $id, status: $status)
  }
`;
