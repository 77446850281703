import gql from "graphql-tag";

export const ALL_USERS = gql`
  query users {
    users {
      id
      facebook
      google
      storyCredit
      storyCreditOnHold
      account {
        id
        firstName
        lastName
        country
        password
        email
        mobile
      }
      createdAt
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($user: UserInput) {
    createUser(user: $user) {
      id
      facebook
      google
      storyCredit
      storyCreditOnHold
      account {
        id
        firstName
        lastName
        country
        password
        email
        mobile
      }
      createdAt
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($user: UpdateUserInput) {
    updateUser(user: $user) {
      id
      facebook
      google
      storyCredit
      storyCreditOnHold
      account {
        id
        firstName
        lastName
        country
        password
        email
        mobile
      }
      createdAt
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID) {
    deleteUser(id: $id)
  }
`;
