import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Modal } from "reactstrap";
import classNames from "classnames";
import { useMutation } from "react-apollo";
import { DELETE_ADMIN } from "../Admins-gql";
import { PUBLICURI } from "../../../Configs";

const ModalComponent = ({
  color,
  title,
  colored,
  header,
  rtl,
  toggle,
  modal,
  details,
}) => {
  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  const [deleteAdmin] = useMutation(DELETE_ADMIN);

  const deleteAdminFunc = async () => {
    try {
      const deletes = await fetch(
        `${PUBLICURI}newproduct/delete?newproductId=${details._id}`,
        { method: "DELETE" }
      );
      const deleteJson = await deletes.json();
      if (deleteJson.status == 200) {
        console.log("delete", deleteJson);
        toggle();
        window.location.reload();
      }
    } catch (e) {
      console.log("error", e.message);
    }
    // deleteAdmin({
    //   variables: {
    //     id: details.id,
    //   },
    // })
    //   .then(({ data }) => {
    //     if (data) {
    //
    //     }
    //   })
    //   .catch((error) => error && console.log(error));
  };

  console.log("detail", details);

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName={`${rtl.direction}-support`}
      className={`modal-dialog--${color} ${modalClass}`}
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
        {header ? "" : Icon}
        <h4 className="text-modal  modal__title">{title}</h4>
      </div>
      <div className="modal__body">{title}</div>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel" onClick={toggle}>
          Cancel
        </Button>{" "}
        <Button
          className="modal_ok"
          outline={colored}
          color={color}
          onClick={deleteAdminFunc}
        >
          Delete
        </Button>
      </ButtonToolbar>
    </Modal>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
  // details: state.user.details,
}))(ModalComponent);
