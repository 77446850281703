import React from "react";
import { Route } from "react-router-dom";
import Layout from "../../../Layout/index";
import Account from "./Account";

import Dashboard from "../../../Dashboards/Dashboard";
import Approveal from "../../../ApproveDress/ApproveDress";
import Admins from "../../../Admins/Admin";
import Users from "../../../Users/Users";
import Orders from "../../../Orders/Orders";
import Cashback from "../../../Cashback/Cashback";
import Currency from "../../../Currency/Currency";
import ApproveImport from "../../../ApproveDress/ApproveImport";
import Banner from "../../../Banner/Banner";

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/approve" component={Approveal} />
      <Route path="/import" component={ApproveImport} />
      <Route path="/banner" component={Banner} />
      <Route path="/admins" component={Admins} />
      <Route path="/users" component={Users} />
      <Route path="/orders" component={Orders} />
      <Route path="/cashback" component={Cashback} />
      <Route path="/currency" component={Currency} />

      <Route path="/account" component={Account} />
    </div>
  </div>
);
