import gql from "graphql-tag";

export const ADMIN_DETAILS = `
  id
  type
  storyCredit
  storyCreditOnHold
  createdAt
  account {
    id
    firstName
    lastName
    country
    password
    email
    mobile
  }
`;

export const LOGIN_ADMIN_DETAILS = `
  id
  type
  storyCredit
  storyCreditOnHold
  createdAt
  account {
    id
    firstName
    lastName
    country
    password
    email
    mobile
    token
  }
`;

export const ALL_ADMINS = gql`
  query admins {
      admins {
          ${ADMIN_DETAILS}
      }
  }
`;

export const DELETE_ADMIN = gql`
  mutation deleteAdmin($id: ID) {
    deleteAdmin(id: $id)
  }
`;

export const CREATE_ADMIN = gql`
  mutation createAdmin($admin: AdminInput, $account: AccountInput) {
    createAdmin(admin: $admin, account: $account)
  }
`;

export const UPDATE_ADMIN = gql`
  mutation updateAdmin($admin: UpdateAdminInput) {
    updateAdmin(admin: $admin) {
      type
      storyCredit
      storyCreditOnHold
      createdAt
      account {
        id
        firstName
        lastName
        country
        password
        email
        mobile
        token
      }
    }
  }
`;
