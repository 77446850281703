import gql from "graphql-tag";

export const GET_CURRENCY = gql`
  query currency {
    currency {
      id
      name {
        ar
        en
      }
      icon {
        id
        path
      }
      currency
      createdAt
    }
  }
`;

export const CREATE_CURRENCY = gql`
  mutation createCurrency($currency: CurrencyInput) {
    createCurrency(currency: $currency)
  }
`;

export const DELETE_CURRENCY = gql`
  mutation deleteCurrency($id: ID) {
    deleteCurrency(id: $id)
  }
`;
