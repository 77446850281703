import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import MainWrapper from "../MainWrapper";
import NotFound404 from "../../DefaultPage/404/index";
import LockScreen from "../../Account/LockScreen/index";
import LogIn from "../../Account/LogIn/index";
import WrappedRoutes from "./WrappedRoutes";
import { connect } from "react-redux";

const Router = ({ user }) => (
  <MainWrapper>
    <main>
      <Switch>
        <Route
          exact
          path="/"
          render={() =>
            user.loggedIn ? (
              <Redirect to="/dashboard" />
            ) : (
              <Redirect to="/log_in" />
            )
          }
        />
        <Route path="/lock_screen" component={LockScreen} />
        <Route path="/log_in" component={LogIn} />
        {user.loggedIn ? <Route path="/" component={WrappedRoutes} /> : <Redirect to={'/log_in'} path="*" />}
        <Route component={NotFound404} />
      </Switch>
    </main>
  </MainWrapper>
);

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Router);
