import React, { useState } from "react";
import { PUBLICURI } from "../../../Configs";
import Carousel, { Modal, ModalGateway } from "react-images";

export default ({ images }) => {
  const [state, setState] = useState({
    lightboxIsOpen: false,
    currentImage: 0,
    currentImagePreview: 0,
  });

  const changeImg = (i, e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      currentImagePreview: i,
      currentImage: i,
    }));
  };

  const openLightbox = (index, event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      currentImage: index,
      lightboxIsOpen: true,
    }));
  };

  const closeLightbox = () => {
    setState((prevState) => ({
      ...prevState,
      currentImage: prevState.currentImagePreview,
      lightboxIsOpen: false,
    }));
  };

  const handleClickImage = () => {
    const { currentImage } = state;
    if (currentImage === images.length - 1) return;
    gotoNext();
  };

  const gotoPrevious = () => {
    setState((prevState) => ({
      ...prevState,
      currentImage: prevState.currentImage - 1,
    }));
  };

  const gotoNext = () => {
    setState((prevState) => ({
      ...prevState,
      currentImage: prevState.currentImage + 1,
    }));
  };

  const gotoImage = (index) => {
    setState((prevState) => ({
      ...prevState,
      currentImage: index,
    }));
  };

  const { currentImage, currentImagePreview, lightboxIsOpen } = state;

  return (
    <>
      <div className="product-gallery">
        <a
          className="product-gallery__current-img"
          onClick={(e) => openLightbox(currentImage, e)}
          href={images[currentImage].path}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundImage: `url(${PUBLICURI}${images[currentImagePreview].path})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 50%",
            }}
          />
        </a>
        <div className="product_gallery__gallery">
          {images.map((img, i) => (
            <button
              type="button"
              key={i}
              onClick={(e) => changeImg(i, e)}
              className="product-gallery__img-preview"
            >
              <img src={`${PUBLICURI}${img.path}`} alt="product-img" />
            </button>
          ))}
        </div>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                styles={{
                  view: () => ({
                    height: "100vh",
                    width: "inherit",
                  }),
                }}
                currentImage={currentImage}
                isOpen={lightboxIsOpen}
                onClose={closeLightbox}
                onClickImage={handleClickImage}
                onClickNext={gotoNext}
                onClickPrev={gotoPrevious}
                onClickThumbnail={gotoImage}
                views={images}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        {/* <Lightbox
          currentImage={currentImage}
          images={images}
          isOpen={lightboxIsOpen}
          onClose={closeLightbox}
          onClickImage={handleClickImage}
          onClickNext={gotoNext}
          onClickPrev={gotoPrevious}
          onClickThumbnail={gotoImage}
        /> */}
      </div>
    </>
  );
};
