import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Modal, Row, Col } from "reactstrap";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-apollo";
import PhoneInput from "react-phone-input-2";
import { CREATE_USER, UPDATE_USER } from "../User-gql";
// import ar from "react-phone-input-2/lang/ar.json";

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("firstName"),
});

const ModalComponent = ({
  color,
  title,
  colored,
  header,
  rtl,
  toggle,
  modal,
  details,
  themeName,
  typed,
}) => {
  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  const [createUser] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);

  const formik = useFormik({
    initialValues: {
      firstName:
        (details && details.account && details.account.firstName) || "",
      lastName: (details && details.account && details.account.lastName) || "",
      mobile: (details && details.account && details.account.mobile) || "",
      country: (details && details.account && details.account.country) || "",
      email: (details && details.account && details.account.email) || "",
      password: "",
    },
    onSubmit: ({ email, password, firstName, lastName, mobile, country }) => {
      if (typed === "New") {
        createUserFunc(firstName, lastName, mobile, country, password, email);
      } else {
        updateUserFunc(firstName, lastName, mobile, country, email);
      }
    },
    validationSchema: ValidationSchema,
  });

  const {
    errors,
    touched,
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  const updateUserFunc = (firstName, lastName, mobile, country, email) => {
    updateUser({
      variables: {
        user: {
          id: details.id,
          account: {
            id: details && details.account.id,
            firstName,
            lastName,
            mobile,
            country,
            email,
          },
        },
      },
    })
      .then(({ data }) => {
        if (data) {
          toggle();
          window.location.reload();
        }
      })
      .catch((error) => console.log(error));
  };

  const createUserFunc = (
    firstName,
    lastName,
    mobile,
    country,
    password,
    email
  ) => {
    createUser({
      variables: {
        user: {
          facebook: null,
          google: null,
          account: {
            firstName,
            lastName,
            mobile,
            country,
            password,
            email,
          },
        },
      },
    })
      .then(({ data }) => {
        if (data) {
          toggle();
          window.location.reload();
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName={`${rtl.direction}-support`}
      className={`modal-dialog--${color} ${modalClass} ${themeName}`}
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
        {header ? "" : Icon}
        <h4
          className="text-modal  modal__title"
          style={{
            color: "#fff",
          }}
        >
          {title}
        </h4>
      </div>
      <div className="modal__body">
        <form className="form" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <div className="form__form-group">
                <span className="form__form-group-label">First name</span>
                <div className="form__form-group-field">
                  <input
                    className={
                      errors.firstName && touched.firstName ? "error" : ""
                    }
                    name="firstName"
                    type="text"
                    placeholder="firstName"
                    defaultValue={values.firstName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div className="form__form-group">
                <span className="form__form-group-label">Last name</span>
                <div className="form__form-group-field">
                  <input
                    className={
                      errors.lastName && touched.lastName ? "error" : ""
                    }
                    name="lastName"
                    component="input"
                    type="text"
                    placeholder="Last name"
                    defaultValue={values.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Mobile</span>
                <PhoneInput
                  stlye={{
                    paddingLeft: "48px",
                  }}
                  country="jo"
                  onChange={(e, country) =>
                    setFieldValue("mobile", e) &&
                    setFieldValue("country", country.countryCode)
                  }
                  className={
                    errors.mobile && touched.mobile
                      ? "error form-control"
                      : "form-control"
                  }
                  value={values.mobile}
                  excludeCountries={["il"]}
                  required
                />
              </div>
            </Col>
            <Col md={6} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Email</span>
                <div className="form__form-group-field">
                  <input
                    className={errors.email && touched.email ? "error" : ""}
                    name="email"
                    component="input"
                    type="text"
                    placeholder="Email"
                    defaultValue={
                      details && details.account && details.account.email
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {typed === "New" && (
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Password</span>
                  <div className="form__form-group-field">
                    <input
                      className={
                        errors.password && touched.password ? "error" : ""
                      }
                      name="password"
                      type="password"
                      placeholder="Password"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </form>
      </div>
      <ButtonToolbar className="modal__footer">
        <Button
          className="modal_cancel"
          outline={colored}
          color={"danger"}
          onClick={toggle}
        >
          Cancel
        </Button>{" "}
        <button
          onClick={handleSubmit}
          type="submit"
          className="modal_ok btn btn-primary"
        >
          Save
        </button>
      </ButtonToolbar>
    </Modal>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
};

export default connect((state) => ({
  details: state.user.details,
  themeName: state.theme.className,
  rtl: state.rtl,
}))(ModalComponent);
