import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Modal, Row, Col } from "reactstrap";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
// import ar from "react-phone-input-2/lang/ar.json";
import Select from "react-select";
import { useMutation } from "react-apollo";
import { CREATE_ADMIN, UPDATE_ADMIN } from "../Admins-gql";

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string(),
});

const adminTypeSelect = [
  { label: "SUPERADMIN", value: "SUPERADMIN" },
  { label: "EDITOR", value: "EDITOR" },
  { label: "DRIVER", value: "DRIVER" },
];

const ModalComponent = ({
  color,
  title,
  colored,
  header,
  rtl,
  toggle,
  modal,
  details,
  themeName,
  typed,
}) => {
  const [createAdmin] = useMutation(CREATE_ADMIN);
  const [updateAdmin] = useMutation(UPDATE_ADMIN);

  const formik = useFormik({
    initialValues: {
      firstName:
        (details && details.account && details.account.firstName) || "",
      lastName: (details && details.account && details.account.lastName) || "",
      mobile: (details && details.account && details.account.mobile) || "",
      country: (details && details.account && details.account.country) || "",
      email: (details && details.account && details.account.email) || "",
      password: "",
      type: {
        value: details && details.type,
        label: details && details.type,
      },
    },
    onSubmit: ({
      email,
      password,
      firstName,
      type,
      lastName,
      mobile,
      country,
    }) => {
      if (typed === "New") {
        createAdminFunc(
          type,
          firstName,
          lastName,
          mobile,
          country,
          password,
          email
        );
      } else {
        updateAdminFunc(
          type,
          firstName,
          lastName,
          mobile,
          country,
          password,
          email
        );
      }
    },
    validationSchema: ValidationSchema,
  });

  const {
    errors,
    touched,
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }

  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  const updateAdminFunc = (
    type,
    firstName,
    lastName,
    mobile,
    country,
    password,
    email
  ) => {
    updateAdmin({
      variables: {
        admin: {
          id: details.id,
          account: {
            id: details && details.account.id,
            firstName,
            lastName,
            mobile,
            country,
            password,
            email,
          },
          type: type.value,
        },
      },
    })
      .then(({ data }) => {
        if (data) {
          toggle();
          window.location.reload();
        }
      })
      .catch((error) => console.log(error));
  };

  const createAdminFunc = (
    type,
    firstName,
    lastName,
    mobile,
    country,
    password,
    email
  ) => {
    createAdmin({
      variables: {
        account: {
          firstName,
          lastName,
          mobile,
          country,
          email,
          password,
        },
        admin: {
          type: type.value,
        },
      },
    })
      .then(({ data }) => {
        if (data) {
          toggle();
          window.location.reload();
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName={`${rtl.direction}-support`}
      className={`modal-dialog--${color} ${modalClass} ${themeName}`}
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
        {header ? "" : Icon}
        <h4
          className="text-modal  modal__title"
          style={{
            color: "#fff",
          }}
        >
          {title}
        </h4>
      </div>
      <div className="modal__body">
        <form className="form" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <div className="form__form-group">
                <span className="form__form-group-label">First name</span>
                <div className="form__form-group-field">
                  <input
                    name="firstName"
                    type="text"
                    placeholder="firstName"
                    defaultValue={values.firstName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div className="form__form-group">
                <span className="form__form-group-label">Last name</span>
                <div className="form__form-group-field">
                  <input
                    name="lastName"
                    component="input"
                    type="text"
                    placeholder="lastName"
                    defaultValue={values.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Mobile</span>
                <PhoneInput
                  stlye={{
                    paddingLeft: "48px",
                  }}
                  className="form-control"
                  country="jo"
                  onChange={(e, country) =>
                    setFieldValue("mobile", e) &&
                    setFieldValue("country", country.countryCode)
                  }
                  value={values.mobile}
                  excludeCountries={["il"]}
                  required
                />
              </div>
            </Col>
            <Col md={6} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Email</span>
                <div className="form__form-group-field">
                  <input
                    name="email"
                    component="input"
                    type="text"
                    placeholder="email"
                    defaultValue={
                      details && details.account && details.account.email
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {typed === "New" ? (
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Password</span>
                  <div className="form__form-group-field">
                    <input
                      name="password"
                      type="password"
                      placeholder="password"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
            ) : (
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Password</span>
                  <div className="form__form-group-field">
                    <input
                      name="password"
                      type="password"
                      placeholder="password"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
            )}
            <Col>
              <div className="form__form-group form-group">
                <span className="form__form-group-label">Admin type</span>
                <Select
                  className={errors.type && touched.type ? "error" : ""}
                  placeholder={"select"}
                  options={adminTypeSelect}
                  defaultValue={values.type}
                  onChange={(e) => setFieldValue("type", e)}
                />
              </div>
            </Col>
          </Row>
        </form>
      </div>
      <ButtonToolbar className="modal__footer">
        <Button
          className="modal_cancel"
          outline={colored}
          color={"danger"}
          onClick={toggle}
        >
          Cancel
        </Button>{" "}
        <button
          onClick={handleSubmit}
          type="submit"
          className="modal_ok btn btn-primary"
        >
          Save
        </button>
      </ButtonToolbar>
    </Modal>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
};

export default connect((state) => ({
  themeName: state.theme.className,
  rtl: state.rtl,
  details: state.user.details,
}))(ModalComponent);
