import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Col, Modal, Row } from "reactstrap";
import classNames from "classnames";
import * as Yup from "yup";
import { useFormik } from "formik";
import Dropzone from "react-dropzone";
import { useMutation } from "react-apollo";
import Axios from "axios";

import { CREATE_CURRENCY } from "../Currency-gql";
import { uploadUrl } from "../../../Configs";

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string(),
});

const ModalComponent = ({
  color,
  colored,
  header,
  rtl,
  toggle,
  modal,
  themeName,
}) => {
  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }

  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  const [addNewCurrency] = useMutation(CREATE_CURRENCY);

  const formik = useFormik({
    initialValues: {
      nameAr: "",
      nameEn: "",
      currency: "",
    },
    onSubmit: () => handleSubmits(),
    validationSchema: ValidationSchema,
  });

  const { errors, touched, values, handleChange, handleSubmit } = formik;

  const uploadPictures = async (files) => {
    const data = new FormData();
    data.append("file", files);
    data.append("type", "image");
    return await Axios.post(uploadUrl, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
    })
      .then(({ data }) => data._id)
      .catch(() => false);
  };

  const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  const handleSubmits = async () => {
    let icon = "";

    await asyncForEach(picture, async (pic) => {
      const image = await uploadPictures(pic);
      if (image) {
        icon = image;
      }
    });

    await addNewCurrency({
      variables: {
        currency: {
          name: {
            ar: values.nameAr,
            en: values.nameEn,
          },
          currency: values.currency,
          icon,
        },
      },
    })
      .then(({ data }) => {
        if (data) {
          toggle();
          window.location.reload();
        }
      })
      .catch((error) => error && console.log(error));
  };

  const [files, setFiles] = useState();
  const [picture, setPicture] = useState();

  const onDrop = (files) => {
    setPicture(files);
    setFiles(
      files.map((fl) =>
        Object.assign(fl, {
          preview: URL.createObjectURL(fl),
        })
      )
    );
  };

  const removeFile = (index, e) => {
    e.preventDefault();
    setPicture([]);

    setFiles(files.filter((val, i) => i !== index));
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName={`${rtl.direction}-support`}
      className={`modal-dialog--${color} ${modalClass} ${themeName}`}
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
        {header ? "" : Icon}
        <h4
          className="text-modal  modal__title"
          style={{
            color: "#fff",
          }}
        >
          Add new currency
        </h4>
      </div>
      <div className="modal__body">
        <form className="form" onSubmit={handleSubmit}>
          <Row>
            <Col
              style={{
                width: "500px",
              }}
            >
              <div className="dropzone dropzone--multiple">
                <Dropzone
                  multiple={false}
                  className="dropzone__input"
                  accept="image/jpeg, image/png"
                  onDrop={(filesToUpload) => {
                    onDrop(files ? files.concat(filesToUpload) : filesToUpload);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="dropzone__input">
                      {(!files || files.length === 0) && (
                        <div className="dropzone__drop-here">
                          <span className="lnr lnr-upload" /> Drop file here to
                          upload
                        </div>
                      )}
                      <input {...getInputProps()} />
                    </div>
                  )}
                </Dropzone>
                {files && Array.isArray(files) && (
                  <div className="dropzone__imgs-wrapper">
                    {files.map((file, i) => (
                      <div
                        className="dropzone__img"
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          width: "100%",
                          backgroundImage: `url(${file.preview})`,
                        }}
                        key={i}
                      >
                        <p className="dropzone__img-name">{file.name}</p>
                        <button
                          className="dropzone__img-delete"
                          type="button"
                          onClick={(e) => removeFile(i, e)}
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                className={
                  errors.currency && touched.currency
                    ? "form__form-group"
                    : "form__form-group"
                }
              >
                <span className="form__form-group-label">Arabic name</span>
                <div className="form__form-group-field">
                  <input
                    name="nameAr"
                    type="text"
                    placeholder="nameAr"
                    defaultValue={values.nameAr}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div className="form__form-group">
                <span className="form__form-group-label">English name</span>
                <div className="form__form-group-field">
                  <input
                    name="nameEn"
                    component="input"
                    type="text"
                    placeholder="nameEn"
                    defaultValue={values.nameEn}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">Currency</span>
                <div className="form__form-group-field">
                  <input
                    name="currency"
                    component="input"
                    type="text"
                    placeholder="currency"
                    defaultValue={values.currency}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </div>

      <ButtonToolbar className="modal__footer">
        <Button
          className="modal_cancel"
          outline={colored}
          color={"danger"}
          onClick={toggle}
        >
          Cancel
        </Button>{" "}
        <button
          onClick={handleSubmit}
          type="submit"
          className="modal_ok btn btn-primary"
        >
          Add
        </button>
      </ButtonToolbar>
    </Modal>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
};

export default connect((state) => ({
  themeName: state.theme.className,
  rtl: state.rtl,
  details: state.user.details,
  admin: state.user.user,
}))(ModalComponent);
