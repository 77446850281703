/* eslint-disable react/no-unused-state */
import React, { useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import PropTypes from "prop-types";
import EditTable from "../../shared/components/table/DataPaginationTable";
import { useQuery } from "react-apollo";
import Loading from "../../shared/components/Loading";
import Pagination from "../../shared/components/pagination/Pagination";
import * as moment from "moment";
import { connect } from "react-redux";
import { addDetails } from "../../redux/actions/authActions";
import { GET_CASHBACK } from "./Cashback-gql";
import CashbackModal from "./Components/Cashback-modal";

const StatusFormatter = ({ value, row }, toggle) => (
  <>
    <span
      style={{
        cursor: "pointer",
        marginRight: 10,
      }}
      onClick={() => toggle(row, "Edit")}
      className="badge badge-success"
    >
      Pay
    </span>
  </>
);

const customerFormmater = ({ value }) => (
  <div>
    {value.firstName} {value.lastName}
  </div>
);

StatusFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const dateFormatter = ({ value }) => (
  <div>{moment(value).format("YYYY-MM-DD")}</div>
);

export default connect((state) => ({ themeName: state.theme.className }), {
  setDetails: addDetails,
})((props) => {
  const { data, error, loading } = useQuery(GET_CASHBACK);

  return error ? (
    <div>Error</div>
  ) : loading ? (
    <Loading />
  ) : (
    <CashbackContainer data={data} {...props} />
  );
});

const CashbackContainer = ({ data, themeName, setDetails }) => {
  const heads = [
    {
      key: "account",
      name: "Customer Name",
      sortable: true,
      formatter: customerFormmater,
    },
    {
      key: "createdAt",
      name: "Create Date",
      sortable: true,
      formatter: dateFormatter,
    },
    {
      formatter: (value) => StatusFormatter(value, toggle),
    },
  ];

  const filterRows = (originalRows, pageNumber, rowsOnPage) => {
    const rowsFrom = rowsOnPage * (pageNumber - 1);
    const rowsTo = rowsFrom + rowsOnPage;
    return originalRows.slice(rowsFrom, rowsTo);
  };

  const onSorting = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    };
    const { rows, pageOfItems, itemsToShow } = state;
    if (sortDirection !== "NONE") {
      let sortRows = [...rows].sort(comparer);
      sortRows = filterRows(sortRows, pageOfItems, itemsToShow);
      setState((prevState) => ({ ...prevState, rowsToShow: sortRows }));
      return sortRows;
    }
    const sortRows = filterRows(rows, pageOfItems, itemsToShow);
    setState((prevState) => ({ ...prevState, rowsToShow: sortRows }));
    return sortRows;
  };

  const [modal, setModal] = useState(false);

  const toggle = (dress) => {
    setDetails(!modal ? dress : {});
    setModal(!modal);
  };

  const initialPageNumber = 1;
  const initialRowsCount = 10;

  const originalRows = data.cashback;
  const currentPageRows = filterRows(
    originalRows,
    initialPageNumber,
    initialRowsCount
  );

  const [state, setState] = useState({
    rows: originalRows,
    rowsToShow: currentPageRows,
    pageOfItems: initialPageNumber,
    itemsToShow: initialRowsCount,
  });

  const onChangePage = (pageOfItems) => {
    const { rows, itemsToShow } = state;
    if (pageOfItems) {
      const rowsToShow = filterRows(rows, pageOfItems, itemsToShow);
      setState((prevState) => ({ ...prevState, rowsToShow, pageOfItems }));
    }
  };

  return (
    <div className={themeName}>
      {modal && (
        <CashbackModal
          modal={modal}
          toggle={toggle}
          color="primary"
          header
          btn="Default"
        />
      )}
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Cashback list</h5>
            </div>
            <EditTable
              heads={heads}
              rows={state.rowsToShow}
              onSorting={onSorting}
            />
            <Pagination
              itemsCount={data.cashback.length}
              itemsToShow={state.itemsToShow}
              pageOfItems={state.pageOfItems}
              onChangePage={onChangePage}
            />
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};
