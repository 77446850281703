import React, { useEffect, useState } from "react";
import { PUBLICURI } from "../../../Configs";

const useBanner = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const getData = async () => {
    //     setLoading(true);
    try {
      const res = await fetch(`${PUBLICURI}newproduct/newproducts`);
      const resJson = await res.json();
      console.log(resJson.product);
      setData(resJson.product);
    } catch (e) {
      console.log(e);
    }
    //       .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  return [data, loading, error];
};

export default useBanner;
