import gql from "graphql-tag";

export const GET_CASHBACK = gql`
  query cashback {
    cashback {
      id
      user {
        id
        storyCredit
        storyCreditOnHold
        account {
          id
          firstName
          lastName
          mobile
        }
      }
      amount
      details {
        firstName
        lastName
        middleName
        mobile
        email
        transfetTo
        iBan
      }
      createdAt
    }
  }
`;

export const PAY_BACK_CASH = gql`
  mutation payToUserCashback($id: ID, $admin: ID) {
    payToUserCashback(id: $id, admin: $admin)
  }
`;
