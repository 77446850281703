export const AUTHENTICATE = "AUTHENTICATE";
export const AUTHENTICATE_ERROR_AUTH = "AUTHENTICATE_ERROR_AUTH";
export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const ADD_DETAILS = "ADD_DETAILS";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export function auth({ name, avatar }) {
  return {
    type: AUTHENTICATE,
    user: { name, avatar },
  };
}

export function authError(error) {
  return {
    type: AUTHENTICATE_ERROR_AUTH,
    error,
  };
}

export const loginUser = (user = {}) => ({
  type: LOGIN,
  payload: { user },
});

export const logoutUser = () => ({
  type: LOGOUT,
});

export const addDetails = (details) => ({
  type: ADD_DETAILS,
  payload: details,
});

export const changeLang = (lang) => ({
  type: CHANGE_LANGUAGE,
  payload: lang,
});
