import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Modal } from "reactstrap";
import classNames from "classnames";
import { useMutation } from "react-apollo";
import { CHANGE_ORDER_STATUS } from "../Orders-gql";
import Select from "react-select";

const adminTypeSelect = [
  { label: "Pending", value: "Pending" },
  { label: "On the way", value: "On the way" },
  { label: "Deliverd", value: "Deliverd" },
];

const ModalComponent = ({
  color,
  title,
  colored,
  header,
  rtl,
  toggle,
  modal,
  details,
}) => {
  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  const [changeStatus] = useMutation(CHANGE_ORDER_STATUS);

  const [selected, setSelected] = useState({
    label: details.status,
    value: details.status,
  });

  const changeStatusFunc = () => {
    changeStatus({
      variables: {
        id: details.id,
        status: selected.value,
      },
    })
      .then(({ data }) => {
        if (data) {
          toggle();
          window.location.reload();
        }
      })
      .catch((error) => error && console.log(error));
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName={`${rtl.direction}-support`}
      className={`modal-dialog--${color} ${modalClass}`}
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
        {header ? "" : Icon}
        <h4 className="text-modal  modal__title">Order Details</h4>
      </div>
      <div className="modal__body">
        <div>
          name: {details.user && details.user.account.firstName}{" "}
          {details.user && details.user.account.lastName}
        </div>
        <div>mobile: {details.user && details.user.account.mobile}</div>
      </div>
      <div
        className="form__form-group form-group"
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <span className="form__form-group-label">New status</span>
        <Select
          placeholder={"select"}
          options={adminTypeSelect}
          defaultValue={selected}
          isSearchable={false}
          onChange={(e) => setSelected(e)}
        />
      </div>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel" onClick={toggle}>
          Cancel
        </Button>{" "}
        <Button
          className="modal_ok"
          outline={colored}
          color={color}
          onClick={changeStatusFunc}
        >
          Change
        </Button>
      </ButtonToolbar>
    </Modal>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
  details: state.user.details,
}))(ModalComponent);
