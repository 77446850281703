import gql from "graphql-tag";

export const ADMIN_DASHBOARD = gql`
  query adminDashboard {
    adminDashboard {
      users
      products
      admins
    }
  }
`;
