import React from "react";
import LogInForm from "./LogInForm";
import { useMutation } from "react-apollo";
import { ADMIN_LOGIN } from "./Login-gql";

const LoginCard = () => {
  const [adminLogin] = useMutation(ADMIN_LOGIN);

  return (
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">
            Welcome to
            <span className="account__logo">
              {" "}
              admin <span className="account__logo-accent">Layls</span>
            </span>
          </h3>
          <h4 className="account__subhead subhead">Dashboard</h4>
        </div>
        <LogInForm adminLogin={adminLogin} />
      </div>
    </div>
  );
};

export default LoginCard;
