import { ApolloClient } from "apollo-client";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { createHttpLink, HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";

import { GQLURI } from "../Configs";
import { LOGOUT } from "../redux/actions/authActions";
import { store } from "../containers/App/store";

const httpLink = createHttpLink({
  uri: GQLURI,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// const client = new ApolloClient({
//   link: ApolloLink.from([
//     onError(({ graphQLErrors, networkError }) => {
//       if (graphQLErrors)
//         graphQLErrors.map(({ message, locations, path }) => {
//           if (message === "tokenInvalid") {
//             store.dispatch({ type: LOGOUT });
//           }
//           // eslint-disable-next-line
//           return console.log(
//             `Message: ${message}, Location: ${locations}, Path: ${path}`
//           );
//         });
//       if (networkError) console.log(`[Network error]: ${networkError}`); // eslint-disable-line
//     }),
//     setContext(
//       () =>
//         new Promise((resolve) => {
//           resolve({
//             headers: {
//               authorization: `bearer ${
//                 localStorage.getItem("token")
//                   ? localStorage.getItem("token")
//                   : ""
//               }`,
//             },
//           });
//         })
//     ),
//     new HttpLink({
//       uri: GQLURI,
//       credentials: "same-origin",
//     }),
//   ]),
//   cache: new InMemoryCache(),
// });

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
