/* eslint-disable react/no-array-index-key */
import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Progress } from "reactstrap";

const SessionShort = ({ t, data }) => (
  <Col md={12} xl={3} lg={6} xs={12}>
    <Card>
      <CardBody className="dashboard__card-widget">
        <div className="mobile-app-widget">
          <div className="mobile-app-widget__top-line mobile-app-widget__top-line--pink">
            <p className="mobile-app-widget__total-stat">{data}</p>
          </div>
          <div className="mobile-app-widget__title">
            <h5>Users</h5>
          </div>
          <div className="progress-wrap progress-wrap--small progress-wrap--pink-gradient progress-wrap--label-top">
            <Progress value={data}>
              <p className="progress__label">{data}%</p>
            </Progress>
          </div>
        </div>
      </CardBody>
    </Card>
  </Col>
);

SessionShort.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation("common")(SessionShort);
