import axios from "axios";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";

const ApproveImport = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleSubmit = () => {
    const url = "https://api.layls.com/upload/import-products";

    const config = {
      headers: {
        Accept: "application/json",
      },
    };

    const formData = new FormData();
    formData.append("file", selectedFile);

    axios
      .post(url, formData, config)
      .then((res) => {
        alert('Csv is Uploaded Successfully')
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSelectedFile(null);
      });
  };

  return (
    <>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col md={12} lg={12}>
                <Button color="primary">Download Sample</Button>
              </Col>
            </Row>

            <Table size="sm" bordered responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>priceCurrency</th>
                  <th>price</th>
                  <th>oldPriceCurrency</th>
                  <th>oldPrice</th>
                  <th>color</th>
                  <th>selectedColor</th>
                  <th>origin</th>
                  <th>chest</th>
                  <th>waist</th>
                  <th>hip</th>
                  <th>shoulder</th>
                  <th>high</th>
                  <th>length</th>
                  <th>sleeve</th>
                  <th>underlay</th>
                  <th>qty</th>
                  <th>rent</th>
                  <th>sell</th>
                  <th>rentPerHur</th>
                  <th>state</th>
                  <th>status</th>
                  <th>size</th>
                  <th>size_value</th>
                  <th>size_label</th>
                  <th>shape_value</th>
                  <th>shape_label</th>
                  <th>country_value</th>
                  <th>country_label</th>
                  <th>onHold</th>
                  <th>category</th>
                  <th>description</th>
                  <th>type</th>
                  <th>picture</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                  <td>sample data</td>
                </tr>
              </tbody>
            </Table>
            <br />
            <br />
            <Col md={6}>
              <FormGroup>
                <Label for="exampleFile">Choose CSV File</Label>
                <Input
                  id="exampleFile"
                  name="file"
                  type="file"
                  className="border rounded p-1"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />
                <FormText>
                  This is some placeholder block-level help text for the above
                  input. It‘s a bit lighter and easily wraps to a new line.
                </FormText>
                <Button
                  color="primary"
                  className="p-2 px-4 mt-4"
                  type="submit"
                  disable={selectedFile ? false : true}
                  onClick={handleSubmit}
                >
                  Upload
                </Button>
              </FormGroup>
            </Col>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ApproveImport;
