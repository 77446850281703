import gql from "graphql-tag";

export const PRODUCT_GQL = `
  preview
  createdAt
  id
  name
  description
  price
  oldPrice
  type
  color
  selectedColor
  origin
  chest
  waist
  hip
  shoulder
  high
  length
  sleeve
  underlay
  qty
  approve
  visits
  totalTime
  ref
  status
  size {
    label
    value
  }
  shape {
    label
    value
  }
  country {
    label
    value
  }
  onHold
  sold
  category {
    id
    name {
      ar
      en
    }
  }
  pictures {
    id
    path
  }
  user {
    id
    account {
        firstName
        lastName
    }
  }
`;

export const ALLPRODUCTS = gql`
  query allProducts {
    allProducts {
      ${PRODUCT_GQL}
    }
  }
`;

export const APPROVE_DRESS = gql`
  mutation approveProduct($id: ID, $approval: Boolean, $message: String) {
    approveProduct(id: $id, approval: $approval, message: $message)
  }
`;

export const DELETE_DRESS = gql`
  mutation deleteProduct($id: ID) {
    deleteProduct(id: $id)
  }
`;
