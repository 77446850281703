import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Modal, Row, Col } from "reactstrap";
import classNames from "classnames";
import { useFormik, setFieldValue } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
// import ar from "react-phone-input-2/lang/ar.json";
import Select from "react-select";
import { useMutation, useQuery } from "react-apollo";
import { CREATE_ADMIN, UPDATE_ADMIN } from "../Admins-gql";
import { ALLPRODUCTS } from "../../ApproveDress/ApproveDress-gql";
import { PUBLICURI } from "../../../Configs";

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string(),
});

const adminTypeSelect = [
  { label: "SUPERADMIN", value: "SUPERADMIN" },
  { label: "EDITOR", value: "EDITOR" },
  { label: "DRIVER", value: "DRIVER" },
];

const ModalComponent = ({
  color,
  title,
  colored,
  header,
  rtl,
  toggle,
  modal,
  details,
  themeName,
  typed,
}) => {
  const [createAdmin] = useMutation(CREATE_ADMIN);
  const [updateAdmin] = useMutation(UPDATE_ADMIN);
  const { data, loading, error, refetch } = useQuery(ALLPRODUCTS);

  console.log("details", details);

  const formik = useFormik({
    initialValues: {
      title: (details && details.title) || "",
      description: (details && details.description) || "",
      file: "",
      productid: (details && details.productid) || "",
      // type: {
      //   value: details && details.type,
      //   label: details && details.type,
      // },
    },
    onSubmit: ({ title, description, file, productid }) => {
      if (typed === "New") {
        createAdminFunc(title, description, file, productid);
      } else {
        updateAdminFunc(title, description, file, productid);
      }
    },
    validationSchema: ValidationSchema,
  });

  const {
    errors,
    touched,
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }

  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  const updateAdminFunc = async (title, description, file, productid) => {
    var formdata = new FormData();
    formdata.append("title", title);
    formdata.append("description", description);
    formdata.append("productid", productid);
    formdata.append("file", file);

    console.log("sf", formdata);

    const addBanner = await fetch(
      `${PUBLICURI}newproduct/edit?newproductId=${details._id}`,
      {
        method: "PUT",
        body: formdata,
      }
    );
    const addBannerJson = await addBanner.json();
    if (addBannerJson) {
      toggle();
      window.location.reload();
    }
  };

  const createAdminFunc = async (title, description, file, productid) => {
    var form = new FormData();
    form.append("title", title);
    form.append("description", description);
    form.append("productid", productid);
    if (file) {
      form.append("file", file);
    }

    const addBanner = await fetch(`${PUBLICURI}newproduct/new`, {
      method: "POST",
      body: form,
    });
    const addBannerJson = await addBanner.json();
    if (addBannerJson) {
      toggle();
      // window.location.reload();
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName={`${rtl.direction}-support`}
      className={`modal-dialog--${color} ${modalClass} ${themeName}`}
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
        {header ? "" : Icon}
        <h4
          className="text-modal  modal__title"
          style={{
            color: "#fff",
          }}
        >
          {title}
        </h4>
      </div>
      <div className="modal__body">
        <form className="form" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <div className="form__form-group">
                <span className="form__form-group-label">Title</span>
                <div className="form__form-group-field w-100">
                  <input
                    name="title"
                    type="text"
                    className="w-100"
                    placeholder="title"
                    defaultValue={values.title}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
            <Col xs="12">
              <div className="form__form-group">
                <span className="form__form-group-label">Description</span>
                <div className="form__form-group-field w-100">
                  <textarea
                    rows="4"
                    name="description"
                    component="input"
                    type="text"
                    className="w-100"
                    placeholder="description"
                    defaultValue={values.description}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
            <Col xs="12">
              <div className="form__form-group">
                <span className="form__form-group-label">ProductId</span>
                <div className="form__form-group-field">
                  {data &&
                    data.allProducts &&
                    console.log("allProducts", data.allProducts)}
                  {data && data.allProducts && (
                    <>
                      <select
                        className="form-select"
                        name="productid"
                        onChange={handleChange}
                      >
                        <option value="" selected={true} disabled={true}>
                          select product
                        </option>
                        {data.allProducts.map((val, index) => (
                          <option
                            key={index}
                            value={val.id}
                            selected={
                              details &&
                              details.productid &&
                              val.id == details.productid
                                ? true
                                : false
                            }
                          >
                            {val.name}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </div>
              </div>
            </Col>
            <Col xs="12">
              <div className="form__form-group">
                <span className="form__form-group-label">File</span>
                <div className="form__form-group-field">
                  <input
                    name="file"
                    component="file"
                    type="file"
                    placeholder="file"
                    defaultValue={values.file}
                    onChange={(event) => {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }}
                  />
                </div>
              </div>
              {details && details.image && (
                <img
                  src={`${PUBLICURI}public/image/${details.image}`}
                  alt={details.title}
                  height={100}
                />
              )}
            </Col>
          </Row>

          {/* <Row>
            <Col md={6} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Mobile</span>
                <PhoneInput
                  stlye={{
                    paddingLeft: "48px",
                  }}
                  className="form-control"
                  country="jo"
                  onChange={(e, country) =>
                    setFieldValue("mobile", e) &&
                    setFieldValue("country", country.countryCode)
                  }
                  value={values.mobile}
                  excludeCountries={["il"]}
                  required
                />
              </div>
            </Col>
            <Col md={6} sm={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Email</span>
                <div className="form__form-group-field">
                  <input
                    name="email"
                    component="input"
                    type="text"
                    placeholder="email"
                    defaultValue={
                      details && details.account && details.account.email
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {typed === "New" ? (
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Password</span>
                  <div className="form__form-group-field">
                    <input
                      name="password"
                      type="password"
                      placeholder="password"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
            ) : (
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Password</span>
                  <div className="form__form-group-field">
                    <input
                      name="password"
                      type="password"
                      placeholder="password"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
            )}
            <Col>
              <div className="form__form-group form-group">
                <span className="form__form-group-label">Admin type</span>
                <Select
                  className={errors.type && touched.type ? "error" : ""}
                  placeholder={"select"}
                  options={adminTypeSelect}
                  defaultValue={values.type}
                  onChange={(e) => setFieldValue("type", e)}
                />
              </div>
            </Col>
          </Row> */}
        </form>
      </div>
      <ButtonToolbar className="modal__footer">
        <Button
          className="modal_cancel"
          outline={colored}
          color={"danger"}
          onClick={toggle}
        >
          Cancel
        </Button>{" "}
        <button
          onClick={handleSubmit}
          type="submit"
          className="modal_ok btn btn-primary"
        >
          Save
        </button>
      </ButtonToolbar>
    </Modal>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
};

export default connect((state) => ({
  themeName: state.theme.className,
  rtl: state.rtl,
  // details: state.user.details,
}))(ModalComponent);
