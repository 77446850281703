import React, { useState } from "react";
import { Field, reduxForm, Form } from "redux-form";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { Alert, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingIcon from "mdi-react/LoadingIcon";
import { loginUser } from "../../../redux/actions/authActions";
import { useHistory } from 'react-router-dom';

const ValidationSchema = Yup.object().shape({
  email: Yup.string().required("email"),
  password: Yup.string().required("password"),
});

const LogInForm = ({ adminLogin, dispatch }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState("");

  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: ({ email, password }) => {
      setLoading(true);
      adminLogin({
        variables: {
          username: email,
          password,
        },
      })
        .then(({ data: { loginAdmin }, data }) => {
          if (loginAdmin) {
            dispatch(loginUser(loginAdmin));
            
            localStorage.setItem("token", loginAdmin.account.token);
            // setTimeout(() => {
            //   window.location.href = "/dashboard";
            // }, 20);

            setLoading(false);
            history.push('/dashboard');
          } else {
            setError("User not found");
          }
        })
        .catch((error) => {
          setLoading(false);
          error.graphQLErrors.length > 0
            ? setError(
                error.graphQLErrors[0].message === "Wrong Password"
                  ? "Wrong email or password"
                  : "Something went wrong"
              )
            : setError("Something went wrong");
        });
    },
    validationSchema: ValidationSchema,
  });

  const { errors, touched, values, handleChange, handleSubmit } = formik;

  const showPasswordFunc = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <Form className="form login-form" onSubmit={handleSubmit}>
      <Alert color="danger" isOpen={!!errorMessage}>
        {errorMessage}
      </Alert>
      <div className="form__form-group">
        <span className="form__form-group-label">{"Email"}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="email"
            component="input"
            type="text"
            placeholder={"Email"}
            onChange={handleChange}
            value={values.username}
          />
        </div>
        {errors.email && touched.email && (
          <div
            style={{
              color: "red",
            }}
          >
            Email required
          </div>
        )}
      </div>
      <div
        className="form__form-group"
        style={{
          marginBottom: 35,
        }}
      >
        <span className="form__form-group-label">Password</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            name="password"
            component="input"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            onChange={handleChange}
            value={values.password}
          />
          <button
            type="button"
            className={`form__form-group-button${
              showPassword ? " active" : ""
            }`}
            onClick={(e) => showPasswordFunc(e)}
          >
            <EyeIcon />
          </button>
        </div>
        {errors.password && touched.password && (
          <div
            style={{
              color: "red",
            }}
          >
            Password required
          </div>
        )}
      </div>
      <div className="account__btns">
        {loading && (
          <div className="project-summary__statistics-refresh">
            <LoadingIcon />
          </div>
        )}
        <Button className="account__btn" submit="true" color="primary">
          Login
        </Button>
      </div>
    </Form>
  );
};

export default reduxForm({
  form: "login_form", // a unique identifier for this form
})(LogInForm);
