/* eslint-disable react/no-children-prop */
import React from "react";
import { Button, ButtonToolbar } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-apollo";
import { UPDATE_PROFILE } from "./ProfileSetting-gql";
// import { useSelector } from "react-redux";

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string(),
});

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  children,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    children={children}
    value={input.value}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  label: "",
  children: [],
};

const ProfileSettings = ({}) => {
  // const details = useSelector((state) => state.user.details);
  const [updateAdmin] = useMutation(UPDATE_PROFILE);

  const formik = useFormik({
    initialValues: {},
    onSubmit: ({ firstName, lastName, email, password, mobile, country }) => {
      updateAdminFunc(firstName, lastName, email, password, mobile, country);
    },
    validationSchema: ValidationSchema,
  });

  const {
    errors,
    touched,
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  const updateAdminFunc = (
    firstName,
    lastName,
    email,
    password,
    mobile,
    country
  ) => {
    updateAdmin({
      variables: {
        // id: details.id,
        account: {
          // id: details && details.account.id,
          firstName,
          lastName,
          mobile,
          country,
          password,
          email,
        },
        // type: type.value,
      },
    })
      .then(({ data }) => {
        if (data) {
          // toggle();
          // window.location.reload();
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <form className="material-form" onSubmit={handleSubmit}>
      <div>
        <span className="material-form__label">First Name</span>
        <Field
          name="firstName"
          component={renderTextField}
          placeholder="Name"
        />
      </div>
      <div>
        <span className="material-form__label">Last Name</span>
        <Field name="lastName" component={renderTextField} placeholder="Name" />
      </div>
      <div>
        <span className="material-form__label">Email</span>
        <Field
          name="email"
          component={renderTextField}
          placeholder="example@mail.com"
          type="email"
        />
      </div>
      <div>
        <span className="material-form__label">Password</span>
        <Field name="password" component={renderTextField} type="password" />
      </div>
      <div>
        <span className="material-form__label">Mobile No</span>
        <Field name="mobile" component={renderTextField} type="tel" />
      </div>
      <div>
        <span className="material-form__label">Text Area</span>
        <Field
          name="textarea"
          component={renderTextField}
          placeholder="Type message here"
          multiline
          rowsMax="4"
        />
      </div>
      <ButtonToolbar className="form__button-toolbar">
        <Button color="primary" type="submit">
          Update profile
        </Button>
        {/* <Button type="button" onClick={reset}>
          Cancel
        </Button> */}
      </ButtonToolbar>
    </form>
  );
};

// export default connect((state) => ({
//   themeName: state.theme.className,
//   rtl: state.rtl,
//   details: state.user.details,
// }))(ProfileSettings);

export default reduxForm({
  form: "profile_settings_form", // a unique identifier for this form
})(ProfileSettings);
