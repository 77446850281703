import gql from "graphql-tag";

export const PROFILE_DETAILS = `
  id
  type
  storyCredit
  storyCreditOnHold
  createdAt
  account {
    id
    firstName
    lastName
    country
    password
    email
    mobile
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateUser($user: UpdateUserInput) {
    updateUser(user: $user) {
      id
      account {
        id
        firstName
        lastName
        country
        password
        email
        mobile
        lang
      }
    }
  }
`;
