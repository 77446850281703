import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Modal } from "reactstrap";
import classNames from "classnames";
import ProductGallery from "./ApproveGallery";
import { PUBLICURI } from "../../../Configs";
import { useMutation } from "react-apollo";
import { APPROVE_DRESS } from "../ApproveDress-gql";

const ModalComponent = ({
  color,
  btn,
  title,
  message,
  colored,
  header,
  rtl,
  toggle,
  modal,
  details,
  refetch,
}) => {
  const [disapprove, setDisapprove] = useState(false);
  const [messaged, setMessage] = useState("");
  const [increaseVisits] = useMutation(APPROVE_DRESS);

  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  const approveDressFunc = async (approval) => {
    await increaseVisits({
      variables: {
        id: details.id,
        approval,
        message: messaged,
      },
    })
      .then(({ data }) => {
        if (data) {
          refetch();
          toggle();
          setDisapprove(false);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName={`${rtl.direction}-support`}
      className={`modal-dialog--${color} ${modalClass}`}
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
        {header ? "" : Icon}
        <h4 className="text-modal  modal__title">{title}</h4>
      </div>
      <div className="modal__body">
        {details.pictures && details.pictures.length > 0 && (
          <ProductGallery
            images={details.pictures.map((pic) => ({
              src: `${PUBLICURI}${pic.path}`,
              source: `${PUBLICURI}${pic.path}`,
              ...pic,
            }))}
          />
        )}

        <div>name: {details && details.name}</div>
        <div>price: {details && details.price}</div>
        <div>oldPrice: {details && details.oldPrice}</div>
        <div>qty: {details && details.qty}</div>
        <div>chest: {details && details.chest}</div>
        <div>high: {details && details.high}</div>
        <div>hip: {details && details.hip}</div>
        <div>waist: {details && details.waist}</div>
      </div>
      <ButtonToolbar
        className="modal__footer"
        style={{
          marginBottom: disapprove ? 0 : 30,
        }}
      >
        <Button className="modal_cancel" onClick={toggle}>
          Cancel
        </Button>{" "}
        <Button
          className="modal_ok"
          outline={colored}
          color={color}
          onClick={() => {
            approveDressFunc(true);
          }}
          disabled={details.approve}
        >
          Approve
        </Button>
        <Button
          disabled={details.approve}
          className="modal_cancel"
          outline={colored}
          color={"danger"}
          onClick={() => setDisapprove(true)}
        >
          Disapprove
        </Button>
      </ButtonToolbar>
      {disapprove && (
        <div style={{ padding: 20, marginBottom: 30 }}>
          <span>Message</span>
          <textarea
            class="form-control"
            style={{ width: "100%", marginBottom: 10 }}
            type="textarea"
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button
            className="modal_ok"
            outline={colored}
            color={color}
            onClick={() => {
              approveDressFunc(false);
            }}
          >
            Send
          </Button>
        </div>
      )}
    </Modal>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(ModalComponent);
