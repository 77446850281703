/* eslint-disable react/no-unused-state */
import React, { useState } from "react";
import { Button, Card, CardBody, Col } from "reactstrap";
import PropTypes from "prop-types";
import * as moment from "moment";

import EditTable from "../../shared/components/table/DataPaginationTable";
import { useQuery } from "react-apollo";
import { ALLPRODUCTS } from "./ApproveDress-gql";
import Loading from "../../shared/components/Loading";
import Modal from "./Components/ApproveModal";
import Pagination from "../../shared/components/pagination/Pagination";
import DressDeleteModal from "./Components/DressDeleteModal";
import { Link } from "react-router-dom";

const MoneyFormatter = ({ value }) => <div>${value}</div>;

const dateFormatter = ({ value }) => (
  <div>{moment(value).format("YYYY-MM-DD")}</div>
);

const StatusFormatter = ({ value, row }, toggle, deleteToggle) => {
  return (
    <>
      {row && !row.preview ? (
        <span
          style={{
            cursor: "pointer",
            marginRight: 10,
          }}
          onClick={() => toggle(row)}
          className="badge badge-disabled"
        >
          Preview
        </span>
      ) : value ? (
        <span
          style={{
            marginRight: 10,
          }}
          className="badge badge-success"
        >
          Approved
        </span>
      ) : (
        <span
          style={{
            cursor: "pointer",
            marginRight: 10,
          }}
          onClick={() => toggle(row)}
          className="badge badge-danger"
        >
          Disapproved
        </span>
      )}
      <span
        style={{
          cursor: "pointer",
          marginRight: 10,
        }}
        className="badge badge-success"
        onClick={() => {
          // toggle(row, "Edit");
          window.open(`https://layls.com/edit-product/${row.id}`, "_blank");
        }}
      >
        Edit
      </span>
      <span
        style={{
          cursor: "pointer",
          marginRight: 10,
        }}
        onClick={() => deleteToggle(row)}
        className="badge badge-success"
      >
        Delete
      </span>
    </>
  );
};

const customerFormmater = ({ value }) => {
  if (value && value.account && value.account.firstName) {
    return (
      <div>
        {value.account.firstName} {value.account.lastName}
      </div>
    );
  } else {
    return null;
  }
};

StatusFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

export default () => {
  const { data, loading, error, refetch } = useQuery(ALLPRODUCTS);
  console.log(data);

  return error ? (
    <div>Error</div>
  ) : loading ? (
    <Loading />
  ) : (
    <ApprovalContainer data={data} refetch={refetch} />
  );
};

const ApprovalContainer = ({ data, refetch }) => {
  const heads = [
    {
      key: "ref",
      name: "ID",
      width: 80,
      sortable: true,
    },
    {
      key: "user",
      name: "Customer Name",
      sortable: true,
      formatter: customerFormmater,
    },
    {
      key: "price",
      name: "Price",
      sortable: true,
      formatter: MoneyFormatter,
    },
    {
      key: "qty",
      name: "Quantity",
      sortable: true,
    },
    {
      key: "createdAt",
      name: "Created Date",
      sortable: true,
      formatter: dateFormatter,
    },
    {
      key: "approve",
      sortable: true,
      formatter: (value) => StatusFormatter(value, toggle, deleteToggle),
      width: 300,
    },
  ];

  const filterRows = (originalRows, pageNumber, rowsOnPage) => {
    const rowsFrom = rowsOnPage * (pageNumber - 1);
    const rowsTo = rowsFrom + rowsOnPage;
    return originalRows.slice(rowsFrom, rowsTo);
  };

  const onSorting = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    };
    const { rows, pageOfItems, itemsToShow } = state;
    if (sortDirection !== "NONE") {
      let sortRows = [...rows].sort(comparer);
      sortRows = filterRows(sortRows, pageOfItems, itemsToShow);
      setState((prevState) => ({ ...prevState, rowsToShow: sortRows }));
      return sortRows;
    }
    const sortRows = filterRows(rows, pageOfItems, itemsToShow);
    setState((prevState) => ({ ...prevState, rowsToShow: sortRows }));
    return sortRows;
  };

  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [details, setDetails] = useState({});

  const toggle = (dress) => {
    setDetails(!modal ? dress : {});
    setModal(!modal);
  };

  const deleteToggle = (dress) => {
    setDetails(!deleteModal ? dress : {});
    setDeleteModal(!deleteModal);
  };

  const initialPageNumber = 1;
  const initialRowsCount = 10;

  const currentPageRows = filterRows(
    data.allProducts,
    initialPageNumber,
    initialRowsCount
  );

  const [state, setState] = useState({
    rows: data.allProducts,
    rowsToShow: currentPageRows,
    pageOfItems: initialPageNumber,
    itemsToShow: initialRowsCount,
  });

  const onChangePage = (pageOfItems) => {
    const { rows, itemsToShow } = state;
    if (pageOfItems) {
      const rowsToShow = filterRows(rows, pageOfItems, itemsToShow);
      setState((prevState) => ({ ...prevState, rowsToShow, pageOfItems }));
    }
  };

  return (
    <>
      <Modal
        details={details}
        modal={modal}
        toggle={toggle}
        color="primary"
        title="Approve!"
        header
        btn="Default"
        refetch={refetch}
      />
      {deleteModal && (
        <DressDeleteModal
          modal={deleteModal}
          toggle={deleteToggle}
          color="danger"
          title="Delete Dress!"
          header
          btn="Default"
          dress={details}
        />
      )}
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <h5 className="bold-text">Orders list</h5>
                </div>
                <div className="col-auto">
                  <Link to="/import">
                    <Button
                      className="modal_ok"
                      color="primary"
                      // onClick={() => toggle({}, "New")}
                    >
                      Import
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <EditTable
              heads={heads}
              rows={state.rowsToShow}
              onSorting={onSorting}
            />
            <Pagination
              itemsCount={data.allProducts.length}
              itemsToShow={state.itemsToShow}
              pageOfItems={state.pageOfItems}
              onChangePage={onChangePage}
            />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
